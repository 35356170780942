import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Helmet } from "react-helmet"

import Layout from "../components/layout";
import Quote from "../components/projectquote";
import GalleryItem from "../components/galleryitem";
import ShopifyCollection from "../components/shopifycollection";
import Footer from "../components/footer";
import Header from "../components/projectheader";

import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

import styleProject from "../components/projects.module.css";

export default ({ data }) => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{data.projectsJson.name} FAQ - No Goblin</title>
          <link rel="canonical" href="https://nogoblin.com" />
          <meta property="og:title" content={data.projectsJson.name + " FAQ"} />
        <meta property="og:description" content={data.projectsJson.selltext_short} />
        <meta property="og:url" content={"https://nogoblin.com/" + data.projectsJson.urlslug} />
        <meta property="og:image" content="https://nogoblin.com/ms-icon-310x310.png" />
        <meta property="og:site_name" content="No Goblin" />
        <meta property="og:type" content="object" /> 
        </Helmet>

        <div className={styleProject.banner}>
          <BackgroundImage
            fluid={data.projectsJson.frontpage_keyart.childImageSharp.fluid}
            title={data.projectsJson.name}
            className={styleProject.masterContainer}
          >
            <Header twitter={data.projectsJson.twitter} />

            <div className={styleProject.logoContainer}>
              <Link to={"/" + data.projectsJson.urlslug}><Img
                fluid={data.projectsJson.logo.childImageSharp.fluid}
                alt={data.projectsJson.name}
                className={styleProject.logo}
              /></Link>
            </div>
          </BackgroundImage>
        </div>
        <center>

      <div className={styleProject.downstreamContainer}>
        <div className={styleProject.capsule + ' ' + styleProject.faqBody}>
            <div className={styleProject.faqHeader}><h1>Frequently Asked Questions</h1></div>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />

         </div>
      </div>
      <Footer />
      </center>
    </Layout>
  );
};

// The post template's GraphQL query. Notice the “id”
// variable which is passed in. We set this on the page
// context in gatsby-node.js.
//
// All GraphQL queries in Gatsby are run at build-time and
// loaded as plain JSON files so have minimal client cost.
export const pageQuery = graphql`
  query FAQQuery($urlslug: String!) {

    markdownRemark(frontmatter: { project: { eq: $urlslug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }

    # Select the post which equals this id.
    projectsJson(urlslug: { eq: $urlslug }) {
      id
      name
      urlslug
      selltext
      selltext_short
      twitter

      feature_video
      quotes {
        quote
        author
        link
      }

      buy_embed
      buy_alt {
        name
        url
      }

      gallery
      {
        image {
          childImageSharp {
            thumb: fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid_withWebp
            }

            full: fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }


      frontpage
      frontpage_keyart {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
